'use strict'
if (self.location && self.location.protocol === 'blob:') { // local debugging
    const originalFetch = self.fetch.bind(self)

    self.fetch = (url, ...args) => {
        if (url.startsWith('//')) {
            return originalFetch(`https:${url}`, ...args)
        }
        if (url.startsWith('/')) {
            return originalFetch(`${self.location.origin}${url}`, ...args)
        }
        return originalFetch(url, ...args)
    }
}

require('regenerator-runtime/runtime')
const constants = require('./constants/constants')
self.importScripts(constants.LODASH)
self.webBiLogger = require('@wix/web-bi-logger/dist/src/logger')
const ravenWorkerServices = require('./ravenWorkerServices')
ravenWorkerServices.initRavenWorkerServices()

const MessageType = require('./wixCodeMessageTypes')

const renderHandler = require('./worker/renderHandler')
const loadUserGeneratedAppsHandler = require('./worker/loadUserGeneratedAppsHandler')
const AppsStore = require('./stores/AppsStore')
const Store = require('./stores/store')
const MessageService = require('./worker/services/MessageService')
const loggingUtils = require('./utils/loggingUtils')

const scriptsHandler = require('./worker/scriptHandler')
const PubSubService = require('./worker/services/PubSubService.js')
const {reportPlatformInitFedops} = require('./platformInitFedops.js')

const handlersCreator = require('./worker/handlersCreator')
const {createQueue} = require('./utils/queueUntilAllPrevIsProcessed')

const _ = require('lodash')

const store = new Store()
const messageService = new MessageService()
const pubSubService = new PubSubService(messageService)
let sdk

const {bootstrap, load, init, start} = handlersCreator.create({store, messageService, pubSubService})
const initOnce = _.once(init)
const startOnce = _.once(start)
const loadOnce = _.once(load)
const handleRenderedOnce = _.once(renderHandler.handle)
const {setElementoryArguments} = require('./worker/elementoryArguments')
const handleScriptImport = scriptsHandler.handleScript

const MAX_FETCH_DELAY = 500

const appsStore = new AppsStore()
let workerId = null
let bootstrapArgs = null
const getEnv = () => _.get(bootstrapArgs, ['sdkParameters', 'renderingEnv'], 'browser')

const ravenOnErrorCb = self.onerror

self.onerror = function onError(message, filename, lineno, colno, err) {
    ravenOnErrorCb.apply(this, arguments)
    _.invoke(self, 'console.error', err ? err : message)
}

const currentQueue = createQueue([
    {type: MessageType.SCRIPT_IMPORT_MESSAGE, minimumNumOfMessages: Infinity},
    {type: MessageType.BOOTSTRAP},
    {type: MessageType.LOAD_USER_GENERATED_APPS, minimumNumOfMessages: 0}, //TODO: remove the minimum 0 when this message is implemented
    {type: MessageType.LOAD},
    {type: MessageType.INIT},
    {type: MessageType.START}
], processMessage)

self.onmessage = message => {
    const item = {message, type: message.data.type}
    currentQueue.handle(item)

    if (item.type === MessageType.BOOTSTRAP) {
        const fetchScriptsCount = message.data.fetchScriptsCount || 0
        currentQueue.updateMinimumNumberOfMessages(MessageType.SCRIPT_IMPORT_MESSAGE, fetchScriptsCount)
        if (fetchScriptsCount) {
            setTimeout(() => {
                currentQueue.updateMinimumNumberOfMessages(MessageType.SCRIPT_IMPORT_MESSAGE, 0)
            }, MAX_FETCH_DELAY)
        }

        //we do not order messages for santa
        if (!message.data.isBolt) {
            currentQueue.updateMinimumNumberOfMessages(MessageType.LOAD_USER_GENERATED_APPS, 0)
            currentQueue.updateMinimumNumberOfMessages(MessageType.LOAD, 0)
            currentQueue.updateMinimumNumberOfMessages(MessageType.INIT, 0)
            currentQueue.updateMinimumNumberOfMessages(MessageType.START, 0)
        }
    }
}

function processMessage(message) {
    if (!message.data) {
        return
    }

    if (message.data.type === MessageType.BOOTSTRAP) {
        sdk = bootstrap(message.data, appsStore)
        bootstrapArgs = message.data.bootstrapArguments
    } else if (message.data.type === MessageType.START) {
        const handleStart = message.data.shouldSendMoreThenOnce ? start : startOnce
        handleStart(message.data, {
            workerId,
            getAllApps: appsStore.getAll.bind(appsStore),
            env: getEnv(),
            sdk
        })
    } else if (message.data.type === MessageType.SCRIPT_IMPORT_MESSAGE && !sdk) {
        handleScriptImport(message.data)
    }

    if (message.data.type === MessageType.UPDATE_WORKER_BI_DATA) {
        const {updates} = message.data
        loggingUtils.updateBiSessionData(updates)
    }

    if (sdk) {
        switch (message.data.type) {
            case MessageType.LOAD_USER_GENERATED_APPS:
                loadUserGeneratedAppsHandler.loadUserGeneratedApps(message.data, appsStore)
                break
            case MessageType.LOAD:
                workerId = message.data.workerId
                const handleLoad = message.data.shouldSendMoreThenOnce ? load : loadOnce
                handleLoad(message.data, {
                    workerId,
                    getApp: appsStore.get.bind(appsStore),
                    sdk
                })
                break
            case MessageType.INIT:
                const _init = message.data.shouldSendMoreThenOnce ? init : initOnce
                _init(message.data, {
                    workerId,
                    getApp: appsStore.get.bind(appsStore),
                    sdk
                })
                reportPlatformInitFedops(_.keys(message.data.apps), _.keys(appsStore._apps))
                break
            case MessageType.PAGE_RENDERED:
                const handleRendered = message.data.shouldSendMoreThenOnce ? renderHandler.handle : handleRenderedOnce
                handleRendered(sdk)
                break
            case MessageType.UPDATE_WIX_CODE_DATA_AFTER_LOGIN:
                if (message.data.elementoryArguments) {
                    setElementoryArguments(message.data.elementoryArguments)
                }
                break
            case MessageType.SCRIPT_IMPORT_MESSAGE:
                break
            case MessageType.STOP:
                self.close()
                break
            case MessageType.INVOKE_WORKER_SUBSCRIBERS:
                pubSubService.handlePubSubMessage(message.data)
                break
            case MessageType.UPDATE_SITE_MEMBER:
                loggingUtils.updateBiSessionData({siteMemberId: _.get(message, 'data.updates.uid')})
                delegateMessageToSDK(message)
                break
            default:
                delegateMessageToSDK(message)
                break
        }
    }
}

function delegateMessageToSDK(message) {
    if (sdk) {
        sdk.__INTERNAL__.onMessage(message)
    }
}
