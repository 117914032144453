'use strict'

const _ = require('lodash')
const scriptsValidator = require('../scriptsValidator')
const platformUtilities = require('../../utils/platformUtilities')
const platformUtils = require('santa-platform-utils/dist/esm/viewer-platform-worker-api')
const platformServices = require('../../platformServices')
const ravenWorkerServices = require('../../ravenWorkerServices')
const loggingUtils = require('../../utils/loggingUtils')
const {measurePerformanceEnd, measurePerformanceStart} = require('../workerUtils')

const {
    fedops,
    bi,
    ACTION_NAMES
} = loggingUtils
const storeConstants = require('../../constants/store')
const {WIX_CODE_APP_DEF_ID} = require('../../constants/constants')
const {setElementoryArguments} = require('../elementoryArguments')
const {CSRF_TOKEN} = require('../../constants/store')

function createLoadHandler({
    store,
    pubSubService
}) {
    const santaVersion = getSantaVersionFromWorkerUrl(_.get(self, 'location.href'))

    function createRemoteGlobalsInterface(context) {
        const RGI = new platformUtils.RemoteGlobalsInterface(context)
        return RGI
    }

    function initGlobals(RGI, sdkParameters, minimalRoutersMap, sdk) {
        sdk.__INTERNAL__.initGlobals({
            RGI
        })
        sdk.__INTERNAL__.addEnvParams(_.assign({}, sdkParameters, {
            routersMap: minimalRoutersMap
        }))
    }

    function getAppParams(routersMap, appDef, RGI) {
        const {
            id,
            instanceId,
            instance,
            url,
            baseUrls,
            appData
        } = appDef
        return _.pickBy({
            appInstanceId: id,
            appDefinitionId: id,
            instanceId,
            routerReturnedData: RGI.getRouterData(id),
            instance,
            url,
            baseUrls,
            appData,
            appRouters: _.filter(routersMap, routerDef => routerDef.appDefinitionId === id)
        })
    }

    function reportFedOpsInitAppForPageSuccess(appDefinitionId, reportInteractionEnded, beforeInit) {
        reportInteractionEnded({
            duration: _.now() - beforeInit
        })
        fedops.reportAppLoaded({
            appId: appDefinitionId
        })
    }

    function handleInitAppForPageError({
        error,
        pageId,
        appId,
        beforeInit
    }) {
        const err = _.get(error, 'message', '')
        bi.reportPlatformRenderError({
            name: ACTION_NAMES.INIT_APP_FOR_PAGE_FAILED,
            appId,
            pageId,
            error: err,
            duration: Date.now() - beforeInit
        })
        /*eslint-disable no-console*/
        console.error(err)
    }

    function invokeInitAppForPageForAllApplications(apps, routersMap, storageValue, workerId, getApp, sdk, RGI) {
        return Promise.all(apps.map(appDef => {
            const app = getApp(appDef.id)
            if (!app) { // in case the app has failed to load
                return Promise.resolve()
            }

            const appStorage = sdk.getStorage(`${appDef.id}_${appDef.instanceId}`, storageValue)
            const boundedPubSubService = pubSubService.getBoundedAPI(workerId, appDef.id)
            const platformApi = platformUtilities.getApi(routersMap, appStorage, boundedPubSubService)
            const platformServicesAPI = platformServices.getApi(app.appDefId, workerId, store, appDef.instanceId)
            app.platformAPIs = _.merge({}, platformApi, platformServicesAPI)
            app.appParams = getAppParams(routersMap, appDef, RGI)
            app.wixCodeApi = sdk.getScopedGlobalApis(app.appDefId)
            const appDefinitionId = appDef.id
            const {
                reportInteractionStarted,
                reportInteractionEnded
            } = fedops.getInteractionReportFunctions({
                name: ACTION_NAMES.INIT_APP_FOR_PAGE,
                details: app.name,
                params: {
                    appId: appDefinitionId
                }
            })
            reportInteractionStarted()
            const beforeInit = Date.now()
            try {
                // some apps like Groups/Events/Video do not to expose initAppForPage for some reason
                const initAppForPage = _.get(app, 'module.initAppForPage', () => Promise.resolve())
                app.initAppForPageResult = Promise.resolve(initAppForPage(app.appParams, platformApi, app.wixCodeApi, platformServicesAPI)).then(() => {
                    reportFedOpsInitAppForPageSuccess(appDefinitionId, reportInteractionEnded, beforeInit)
                }).catch(error => {
                    handleInitAppForPageError({
                        error,
                        pageId: workerId,
                        appId: appDefinitionId,
                        beforeInit
                    })
                })
            } catch (error) {
                handleInitAppForPageError({
                    error,
                    pageId: workerId,
                    appId: appDefinitionId,
                    beforeInit
                })
                return Promise.reject(new Error(error))
            }
            return Promise.resolve()
        }))
    }

    function getSantaVersionFromWorkerUrl(workerUrl = '') {
        const santaVersionMatch = workerUrl.match(/santa\/([^/]*)/)
        return santaVersionMatch ? santaVersionMatch[1] : 'unknown'
    }

    function getSessionTags() {
        return {
            fullUrl: _.get(self['wix-location'], 'url'),
            viewMode: store.getValue(storeConstants.VIEW_MODE),
            santaVersion
        }
    }

    function getSessionParameters() {
        return {
            renderingEnvironment: store.getValue(storeConstants.RENDERING_ENV),
            referrer: _.get(self['wix-window'], 'referrer')
        }
    }

    function getUrl() {
        return _.get(self['wix-location'], 'url') || _.get(self, 'location.href')
    }

    function sessionDataCallback() {
        return {
            extra: getSessionParameters(),
            tags: getSessionTags(),
            request: {
                url: getUrl()
            }
        }
    }

    function getWixcodeBiServices(userCodeContextIds) {
        const viewMode = store.getValue(storeConstants.VIEW_MODE)
        return _.assign(loggingUtils.getBiServices(), {
            userCodeContextIds,
            viewMode
        })
    }

    function addAppSpecificDataIfNeeded(applications, data) {
        const appDef = _.find(applications, {
            id: WIX_CODE_APP_DEF_ID
        })
        if (appDef) {
            appDef.appData = {
                userCodeMap: data.wixCode
            }
        }
    }

    function handleLoad({
        applications,
        routersMap,
        wixCode,
        elementoryArguments,
        sdkParameters,
        storage,
        rgi,
        livePreviewMode,
        doNotLoadUserCode,
        csrfToken,
        biSessionData,
        openExperiments
    }, {
        workerId,
        getApp,
        sdk
    }) {
        measurePerformanceStart('load')
        if (csrfToken) {
            store.setValue(CSRF_TOKEN, csrfToken)
        }
        store.setValue('appStudioWidgetsStructureUrl', rgi.appStudioWidgetsStructureUrl)
        store.setValue('additionalGhostStructure', rgi.appStudioWidgetsGhostStructures)
        if (!_.isArray(applications)) {
            throw new Error('Load message data must include applications property of type Array')
        }

        if (sdkParameters.renderingEnv) {
            store.setValue(storeConstants.SDK_PARAMETERS, sdkParameters)
        }

        if (openExperiments) {
            store.setValue(storeConstants.OPENED_EXPERIMENTS, openExperiments)
        }

        loggingUtils.updateBiStoreData({
            reportTrace: _.includes(openExperiments, 'sv_reportTrace'),
            fedopsNoSampling: _.includes(openExperiments, 'fedopsNoSampling')
        })

        loggingUtils.updateBiSessionData(_.assign({}, biSessionData, {
            pageId: _.get(rgi, 'navigation.currentPageId', ''),
            pageUrl: _.get(rgi, 'navigation.currentPageFullUrl', ''),
            isServerSide: store.getValue(storeConstants.RENDERING_ENV) === 'backend'
        }))

        if (!routersMap) {
            throw new Error('Load message data must include routersMap of type object')
        }

        scriptsValidator.validate(wixCode, ['id', 'url', 'scriptName', 'displayName'])

        addAppSpecificDataIfNeeded(applications, {wixCode})

        if (elementoryArguments) {
            setElementoryArguments(elementoryArguments)
        }
        const minimalRoutersMap = _.mapValues(routersMap, value => ({
            prefix: value.prefix,
            pages: value.pages
        }))

        const RGI = createRemoteGlobalsInterface(rgi)
        initGlobals(RGI, sdkParameters, minimalRoutersMap, sdk)
        sdk.__INTERNAL__.addLivePreviewMode(livePreviewMode)

        const renderingEnv = store.getValue(storeConstants.RENDERING_ENV)
        const queryParamKeys = _.keys(_.get(self['wix-location'], 'query'))
        const sessionShouldSendCallback = ravenWorkerServices.createSessionShouldSendCallback(renderingEnv, queryParamKeys)
        ravenWorkerServices.setSessionShouldSendCallback(sessionShouldSendCallback)
        ravenWorkerServices.setSessionDataCallback(sessionDataCallback)

        if (!_.isEmpty(wixCode) && !doNotLoadUserCode) {
            sdk.__INTERNAL__.setBiServices(getWixcodeBiServices(_.map(wixCode, 'id')))
            sdk.__INTERNAL__.addAppStudioGlobalsIfNeeded()
        }

        return invokeInitAppForPageForAllApplications(applications, routersMap, storage, workerId, getApp, sdk, RGI).then(() => {
            measurePerformanceEnd('load')
        })
    }

    return handleLoad
}

module.exports = createLoadHandler
